// src/components/NoteTaking.js
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import the styles for Quill
import './NoteTaking.css';

const NoteTaking = () => {
  const [note, setNote] = useState('');
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    // Fetch the last two notes when the component is loaded

    fetch('https://api.sneaknotes.com/api/notes/lastTwo',{
      method: 'GET'
      
    })
      .then((response) => response.json())
      .then((data) => setNotes(data))
      .catch((err) => console.error('Error fetching notes:', err));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (note.trim()) {
      // Send note to backend
      fetch('https://api.sneaknotes.com/api/notes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ content: note }), // Send the rich text content
      })
        .then((response) => response.json())
        .then((data) => {
          setNotes(data); // Update with last two notes
          setNote(''); // Clear the editor
        })
        .catch((err) => console.error('Error sending note:', err));
    }
  };

  return (
    <div className="note-taking-container">
      <h1>Take a Note</h1>
      <form className="note-form" onSubmit={handleSubmit}>
        {/* Replace input with ReactQuill */}
        <ReactQuill
          value={note}
          onChange={setNote}
          theme="snow"
          placeholder="Write your note here"
        />
        <button className="note-button" type="submit">
          Submit
        </button>
      </form>
      <div className="notes-list">
        <h2>Last Two Notes</h2>
        <ul>
          {notes.map((n, index) => (
            // Render the content as HTML
            <li key={index} dangerouslySetInnerHTML={{ __html: n.content }}></li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NoteTaking;
