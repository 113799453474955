
import NoteTaking from './component/NoteTaking';

function App() {
  return (
    <div className='App'>
      <NoteTaking/>
    </div>
  );
}

export default App;
